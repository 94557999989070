


const defaultState = {
  songs: [
    { title: 'No Scrubs', duration: '4:05' },
    { title: 'Macarena', duration: '2:30' },
    { title: 'All Star', duration: '3:15' },
    { title: 'I Want it That Way', duration: '1:45' }
  ],
  selectedSong: null
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case 'SONG_SELECTED': 
      return {
        ...state,
        selectedSong: action.payload
      };
  }
  return state
}